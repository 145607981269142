body {
    margin: 0;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

h1, h2, h3, h4, h5, h6 {
    color: white;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
}
p,a, li {
    color: white;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}


.permanent-marker-regular  {
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
}

.rubik-clasic {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

